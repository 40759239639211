
import { defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CModal from "@/components/modal.vue";

import { ProcedureCartForm } from "@/store/cart/types";

const VProcedureAdd = defineComponent({
  name: "VProcedureAdd",
  components: { CModal },
  setup() {
    useHead({ title: "Agendar procedimento | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const loading = reactive({ submit: false });

    const form = reactive<ProcedureCartForm>({
      cd_procedimento: [Number(route.query.pe)],
      cd_estabelecimento: Number(route.query.et?.toString()) || null,
      cd_pessoa_fisica: Number(route.query.pf?.toString()) || null,
    });

    function showErrorMessage() {
      store.commit("setToast", [
        {
          summary: "OPS... Tivemos problemas ao tentar agendar essa consulta, tente novamente!",
          severity: "error",
        },
      ]);
    }

    function validate() {
      return form.cd_estabelecimento && form.cd_pessoa_fisica && form.cd_procedimento && Number(route.query.hr);
    }

    async function addProcedureToCart() {
      try {
        loading.submit = true;
        const response = await store.dispatch("addProcedureToCart", {
          _id: Number(route.query.hr),
          form,
        });
        loading.submit = false;

        if (!response) throw "";

        store.commit("setToast", [{ summary: "Agendamento adicionado ao carrinho!", severity: "success" }]);
        router.replace({ name: "cart" });
      } catch {
        showErrorMessage();
      }
    }

    function handleClose() {
      router.back();
    }

    if (!validate()) {
      store.commit("addToast", {
        summary: "Dados para o agendamento desse procedimento invalidos!",
        severity: "error",
      });

      router.replace({ name: "procedure", query: route.query });
    }

    return { loading, handleClose, addProcedureToCart };
  },
});

export default VProcedureAdd;
